import React from "react";
import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";
import MarkerClusterGroup from "react-leaflet-markercluster";

import moment from "../../helpers/moment";

import {
  Box,
  CircularProgress,
} from '@material-ui/core';

import icon from 'leaflet/dist/images/marker-icon.png';
import iconShadow from 'leaflet/dist/images/marker-shadow.png';
import "leaflet/dist/leaflet.css";
import "react-leaflet-markercluster/dist/styles.min.css";
import "./styles.css";

const LeafletMap = ({ componentProps }) => {
  const endpoint = componentProps.endpoint
  const mapTitle = componentProps.mapTitle
  let [activities, setActivities] = React.useState(null);
  // let [locations, setLocations] = React.useState(null);
  let [loading, setLoading] = React.useState(true);

  React.useEffect(() => {
    fetch(endpoint)
      .then((response) => response.json())
      .then((data) => {
        // console.log(data);
        // console.log(data.data.activities.data);
        setActivities(data.data.activities.data);
        setLoading(false);
      });
  }, [endpoint]);

  // console.log(endpoint, mapTitle, activities);

  if (loading) return (
    <Box display="flex" flexDirection="column" alignItems="center">
      <Box textAlign="center" mb={2}>Se încarcă...</Box>
      <CircularProgress color="primary" />
    </Box>
);

  let DefaultIcon = L.icon({
    iconUrl: icon,
    shadowUrl: iconShadow,
    iconSize: [25, 41],
    iconAnchor: [10, 41],
    popupAnchor: [2, -45]
  });
  L.Marker.prototype.options.icon = DefaultIcon;

  const MapPlaceholder = ({ title }) => {
    return (
      <>
        <h2>{title}</h2>
        <noscript>You need to enable JavaScript to see this map.</noscript>
      </>
    );
  };

  // const MAPBOX_API_key = 'pk.eyJ1IjoiY2VzdC1ybyIsImEiOiJja3U2cnp6YjA1MHhkMnZucWhmZ2sxNGxvIn0.flO7hFqUveULO2f4qDOr3A'

  // console.log(process.env.MAPBOX_API_key);

  return (
    <MapContainer
      center={componentProps.center || [46, 25]}
      zoom={componentProps.zoom || 7}
      maxZoom={componentProps.maxZoom || 18}
      minZoom={componentProps.minZoom || 6}
      placeholder={mapTitle ? <MapPlaceholder title={mapTitle} /> : null}
    >
      <TileLayer
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
        // url={`https://api.mapbox.com/styles/v1/mapbox/streets-v11/tiles/256/{z}/{x}/{y}@2x?access_token=${process.env.MAPBOX_API_key}`}
        // attribution='Map data &copy; <a href="https://www.openstreetmap.org/">OpenStreetMap</a> contributors, <a href="https://creativecommons.org/licenses/by-sa/2.0/">CC-BY-SA</a>, Imagery &copy; <a href="https://www.mapbox.com/">Mapbox</a>'
      />
      <MarkerClusterGroup>
        {activities.length &&
          activities.map((activity) =>
            activity.locations.map(
              (marker, index) =>
                index === 0 &&
                marker.latitude &&
                marker.longitude && (
                  <Marker
                    position={[marker.latitude, marker.longitude]}
                    key={`${activity.uuid}-${marker.uuid}`}
                  >
                    <Popup>
                      <h4>{activity.title}</h4>
                      <p>
                        {moment(activity.startDate).format("dddd, D MMMM YYYY")}{" "}
                        {activity.startDate !== activity.endDate &&
                          `- ${moment(activity.endDate).format(
                            "dddd, D MMMM YYYY"
                          )}`}
                        <br />
                        <small>{marker.address}</small>
                      </p>
                      <p>
                        <img
                          src={activity.heroImage}
                          alt={activity.title}
                          style={{ minWidth: "256px", maxWidth: "100%" }}
                        />
                      </p>
                      <p style={{ textAlign: "center" }}>
                        <a
                          href={`https://scicommtoolbox.ro/activitati/${activity.slug}`}
                          rel="noreferrer"
                          target="_blank"
                        >
                          Detalii
                        </a>
                      </p>
                    </Popup>
                  </Marker>
                )
            )
          )}
      </MarkerClusterGroup>
    </MapContainer>
  );
};

export default LeafletMap;
