import React from 'react'
import PropTypes from 'prop-types'

import clsx from 'clsx'
import {
  alpha,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  // ListItemIcon,
  Chip,
  Badge,
  Avatar,
  Tooltip,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/styles';

import DefaultIcon from '@material-ui/icons/FiberManualRecordOutlined'

const useStyles = makeStyles(theme => ({
  tooltip: {
    padding: 0,
    backgroundColor: '#ffd653',
    color: theme.palette.primary.main,
    boxShadow: theme.shadows[15],
    fontWeight: theme.typography.fontWeightBold,
  },
  tooltipList: {
    minWidth: theme.spacing(32),
    padding: 0,
    borderRadius: theme.shape.borderRadius,
  },
  tooltipListItem: {
    padding: theme.spacing(1, 0, 1, 2),
  },

  chip: {
    borderRadius: theme.shape.borderRadius,
    color: theme.palette.common.white,
  },
  chipIcon: {
    color: theme.palette.common.white,
  },
  chipUpdated: {
    backgroundColor: 'transparent',
    color: theme.palette.text.primary,
  },

  avatar: {
    position: 'absolute',
    // width: theme.spacing(16),
    // height: theme.spacing(16),
    width: '100%',
    height: '100%',
    padding: theme.spacing(0),
    borderRadius: '50%',
    backgroundColor: 'transparent',
    // border: `${theme.spacing(1.5)} solid ${alpha(
    //   theme.palette.primary.main,
    //   0.7,
    // )}`,
    color: theme.palette.text.primary,
    fontSize: theme.typography.h4.fontSize,
    fontWeight: theme.typography.fontWeightRegular,
    textAlign: 'center',
  },

  badgeRoot: {
    position: 'relative',
    width: '100%',
    height: 0,
    paddingBottom: '100%',
  },
  badge: {
    position: 'absolute',
    width: theme.spacing(8),
    height: theme.spacing(8),
    backgroundColor: theme.palette.primary.main,
    borderRadius: '50%',
    color: theme.palette.common.white,
    fontSize: theme.typography.h4.fontSize,
    fontWeight: theme.typography.fontWeightBold,

    '& > .MuiSvgIcon-root': {
      color: `${theme.palette.common.white}!important`,
    },
  },
  badgeDefault: {
    backgroundColor: theme.palette.success.main,
    borderColor: theme.palette.success.main,
  },
  badgeDefaultAvatar: {
    // backgroundColor: alpha(theme.palette.success.main, 0.2),
    backgroundColor: 'transparent',
    borderColor: theme.palette.success.main,
    color: theme.palette.success.main,
  },
}))

const StatusAvatar = ({
  tooltipPlacement,
  tooltipListData,
  badges,
  title,
  src,
  total,
}) => {
  const classes = useStyles()

  // console.log(tooltipPlacement, tooltipListData, badges)

  return (
    <Tooltip
      component="div"
      placement={tooltipPlacement}
      title={
        <List className={classes.tooltipList}>
          {tooltipListData.map((item, index) => {
            const id = index
            return (
              <ListItem
                dense
                divider
                className={classes.tooltipListItem}
                key={id}
              >
                <ListItemText primary={item.title} />
                <ListItemSecondaryAction>
                  <Chip
                    edge="end"
                    // variant="outlined"
                    size="small"
                    classes={{
                      root: clsx(classes.chip, classes.chipUpdated),
                      icon: classes.chipIcon,
                    }}
                    label={item.label}
                    // icon={getAppointmentStatus(status)}
                  />
                </ListItemSecondaryAction>
              </ListItem>
            )
          })}
        </List>
      }
      classes={{ tooltip: classes.tooltip }}
    >
      <Badge
        invisible={false}
        component="div"
        // eslint-disable-next-line react/jsx-curly-brace-presence
        overlap={'circular'}
        anchorOrigin={{
          vertical: badges.anchor.vertical,
          horizontal: badges.anchor.horizontal,
        }}
        badgeContent={total}
        classes={{
          root: classes.badgeRoot,
          badge: clsx(
            classes.badge,
            // eslint-disable-next-line dot-notation
            // classes['badgeDefault'],
          ),
        }}
        // eslint-disable-next-line react/jsx-curly-brace-presence
        key={'id'}
      >
        <Avatar
          className={clsx(
            classes.avatar,
            // eslint-disable-next-line dot-notation
            classes['badgeDefaultAvatar'],
          )}
          src={src === null ? null : src}
          alt={title}
        >
          {src === null ? title.toString().substring(0, 6) : null}
        </Avatar>
      </Badge>

      {
        // badges.map((badge, index) => {
        //   const id = index;
        //   return (
        //     <span key={id}>bla</span>
        //   );
        // })
      }
    </Tooltip>
  )
}

StatusAvatar.propTypes = {
  tooltipPlacement: PropTypes.string,
  tooltipListData: PropTypes.array,
  badges: PropTypes.object,
  title: PropTypes.string,
  src: PropTypes.string,
  total: PropTypes.number || PropTypes.string,
}

StatusAvatar.defaultProps = {
  tooltipPlacement: 'right',
  tooltipListData: [
    {
      title: 'Title',
      label: '{unavailable}',
    },
  ],
  badges: {
    overlap: 'circle',
    anchor: { vertical: 'bottom', horizontal: 'right' },
    icon: DefaultIcon,
    style: 'badgeDefault',
    avatar: {
      content: 'AB',
      style: 'badgeDefaultAvatar',
    },
  },
  title: 'Title',
  src: '',
  total: ' / ',
}

export default StatusAvatar
