import React from 'react'
import { NavLink } from 'react-router-dom'

import clsx from 'clsx'
import { makeStyles } from '@material-ui/styles';
import { Box, Typography, Button, TextField, Autocomplete, Alert, Divider } from '@material-ui/core'

import SciComm from './SciComm'
import LeafletMap from './Maps/Leaflet'
import ProgramCard from './ProgramCard'
import Team from './Team'
import TeamMember from './TeamMember'
import Organizations from './Organizations'
import OrganisationLogo from './OrganisationLogo'
import HomeCards from './HomeCards'
import HomeCard from './HomeCard'

import IframeHero from './IframeHero'

const useStyles = makeStyles((theme) => ({
  imageContainer: {
    margin: '32px auto',
    textAlign: 'center',
  },
  pageBanner: {
    width: '100%',
    margin: theme.spacing(0, 0, 3),
  },
  floatImage: {
    float: 'left',
    width: theme.spacing(32),
    marginRight: theme.spacing(6),
    marginBottom: theme.spacing(3),
    [theme.breakpoints.up('lg')]: {
      width: theme.spacing(45.5),
    },
    [theme.breakpoints.only('xs')]: {
      width: '100%',
    },
  },
  fullImage: {
    width: '100%',
  },

  youTubeContainer: {
    position: 'relative',
    // width: '100%',
    width: '66%',
    height: 0,
    margin: '32px auto',
    // paddingTop: '56.25%',
    paddingTop: '43.94%',
  },
  youTubeFrame: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
  },

  contentButton: {
    margin: theme.spacing(3, 1, 3, 0),
  },

  GSHTypography: {
    marginBottom: theme.spacing(2),
    // '&>ul, &>ol': {
    //   paddingLeft: 0
    // },
    // '&>ul>li, &>ol>li': {
    //   paddingLeft: theme.spacing(5)
    // }
    '&>p': {
      margin: 0,
    },
  },
}))

const Image = (props) => {
  const classes = useStyles()
  return (
    <Box className={classes.imageContainer}>
      <img
        src={props.children}
        className={props.fullImage ? classes.fullImage : classes.floatImage}
        alt={props.title}
      />
    </Box>
  )
}
const PageBanner = (props) => {
  const classes = useStyles()
  return (
    <Box className={classes.imageContainer}>
      <img
        src={props.children}
        className={classes.pageBanner}
        alt={props.title || props.children}
      />
    </Box>
  )
}

const YouTube = ({src, autoplay}) => {
  const classes = useStyles()
  const source = autoplay ? [src, '?autoplay=1&origin=https://noapteacercetatorilor.educatiepentrustiinta.ro'].join('') : src
  return (
    <Box className={classes.youTubeContainer}>
      {/* <img src={`/images/${src.children}.png`} className={classes.pageBanner} alt={src.children} /> */}
      <iframe
        width="600"
        height="337.5"
        src={source}
        frameBorder="0"
        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
        className={classes.youTubeFrame}
        title="youTubeFrame"
      />
    </Box>
  )
}

const MUIButton = (props) => {
  const classes = useStyles()
  // console.log(props)
  return (
    <Button
      {...props.componentProps}
      href={props.href}
      className={clsx(classes.contentButton, props.className)}
    >
      {props.title}
    </Button>
  )
}

const MUILink = (props) => {
  const classes = useStyles()
  // console.log(props)
  return (
    <Button
      component={NavLink}
      {...props.componentProps}
      to={props.to}
      exact
      className={clsx(classes.contentButton, props.className)}
    >
      {props.title}
    </Button>
  )
}

const ComboBox = ({ data, props, label }) => {
  return (
    <Autocomplete
      {...props}
      options={data}
      getOptionLabel={(option) => option.title}
      // style={{ width: 300 }}
      renderInput={(params) => (
        <TextField {...params} label={label} />
      )}
    />
  )
}

const components = {
  Image: Image,
  YouTube: YouTube,
  Divider: Divider,
  Alert: Alert,
  Box: Box,
  Typography: Typography,
  Button: MUIButton,
  NavLink: MUILink,
  PageBanner: PageBanner,
  ComboBox: ComboBox,
  SciComm: SciComm,
  LeafletMap: LeafletMap,
  ProgramCard: ProgramCard,
  Team: Team,
  TeamMember: TeamMember,
  Organizations: Organizations,
  OrganisationLogo: OrganisationLogo,
  HomeCards: HomeCards,
  HomeCard: HomeCard,
  IframeHero: IframeHero,
}

export default components
