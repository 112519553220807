import React from 'react'
// import PropTypes from 'prop-types'
import { NavLink } from 'react-router-dom'

import {
  StyledEngineProvider,
  Grid, Paper, Box, Typography, Button
} from '@material-ui/core'
import { makeStyles } from '@material-ui/styles';

const itemSpacing = 2
const useStyles = makeStyles((theme) => ({
  heroGrid: {
    margin: theme.spacing(
      theme.shape.verticalSpacer,
      0,
      theme.shape.verticalSpacer * 2,
    ),

    [theme.breakpoints.up('sm')]: {
      // margin: theme.spacing(0),
    },
    [theme.breakpoints.only('xs')]: {
      // margin: theme.spacing(0),
    },
  },
  heroContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  iframeContainer: {
    position: 'relative',
    width: '100%',
    margin: '0 auto',
    paddingTop: '56.25%',
    // borderRadius: theme.shape.borderRadius
  },

  iframe: {
    position: 'absolute',
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    width: '100%',
    height: '100%',
    border: 0,
  },

  titleContainer: {
    marginBottom: theme.spacing(theme.shape.verticalSpacer),
  },

  caption: {
    width: '100%',
    padding: theme.spacing(0.5, 0),
    borderTop: `2px solid ${theme.palette.tertiary.main}`,
    textAlign: 'center',
  },

  buttonContainer: {
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(itemSpacing),
    },
    [theme.breakpoints.only('xs')]: {
      marginTop: theme.spacing(itemSpacing * 4),
    },
  },
}))

const IframeHero = ({ url, teme, program, title, children }) => {
  const classes = useStyles()
  const showSideBar = teme.show || program.show
  const heroGridSizeProps = showSideBar ? { xs: 12, sm: 8, md: 10 } : { xs: 12 }
  // console.log({showSideBar})
  return (
    <StyledEngineProvider injectFirst>
      <Grid container spacing={0} className={classes.heroGrid}>
        <Grid item {...heroGridSizeProps}>
          <Paper className={classes.heroContainer}>
            <Box className={classes.iframeContainer}>
              <iframe src={url} title={title} className={classes.iframe} />
            </Box>
            <Typography
              variant="caption"
              component="div"
              className={classes.caption}
            >
              {children}
            </Typography>
          </Paper>
        </Grid>
        {showSideBar &&
          <Grid item xs={12} sm={4} md={2}>
            <Box className={classes.buttonContainer}>
              <Grid container spacing={itemSpacing}>
                {teme.show && (
                  <Grid item xs={12}>
                    <Button
                      fullWidth
                      variant="contained"
                      color="primary"
                      size="large"
                      component={NavLink}
                      to={teme.path}
                    >
                      Teme
                    </Button>
                  </Grid>
                )}
                {program.show && (
                  <Grid item xs={12}>
                    <Button
                      fullWidth
                      variant="contained"
                      color="secondary"
                      size="large"
                      component={NavLink}
                      to={program.path}
                    >
                      Program
                    </Button>
                  </Grid>
                )}
              </Grid>
            </Box>
          </Grid>
        }
      </Grid>
    </StyledEngineProvider>
  )
}

IframeHero.propTypes = {}

export default IframeHero
