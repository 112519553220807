import React from 'react'

import Masonry from "react-masonry-css";

import {
  StyledEngineProvider,
  useTheme,
  Box,
  CircularProgress,
  Typography,
  Alert, AlertTitle,
} from '@material-ui/core';
import {
  makeStyles,
} from '@material-ui/styles';

import ActivityCard from './ActivityCard'

const useStyles = makeStyles((theme) => ({
  masonryContainer: {},
  masonryGrid: {
    display: "flex",
    // marginLeft: -30,
    margin: theme.spacing(0, -2),
    // paddingTop: theme.spacing(1),
    // padding: theme.spacing(2),
    width: "auto"
  },
  masonryGridColumn: {
    padding: theme.spacing(2),
    backgroundClip: "padding-box",
    "& > div": {
      marginBottom: theme.spacing(4)
    }
  },
}))

function SciComm({ componentProps, title, description }) {
  const theme = useTheme();
  const classes = useStyles();

  const [activities, setActivities] = React.useState([])
  const [loading, setLoading] = React.useState(true)

  const columns = componentProps.columns ? componentProps.columns : {'default':4,'xl':4,'lg':3,'md':2,'sm':1}
  const masonryBreakpointColumns = {
    default: columns.default,
    [theme.breakpoints.values.xl]: columns.xl,
    [theme.breakpoints.values.lg]: columns.lg,
    [theme.breakpoints.values.md]: columns.md,
    [theme.breakpoints.values.sm]: columns.sm
  };

  //
  // SciComm REQUESTS
  // ERROR
  // https://api.scicommtoolbox.ro/api/event-activities/plm
  // NC 2021
  // https://api.scicommtoolbox.ro/api/event-activities/noaptea-cercetatorilor-2021
  // NC 2021, 4 per page
  // https://api.scicommtoolbox.ro/api/event-activities/noaptea-cercetatorilor-2021?page=1&perPage=4
  // NC 2021, 4 per page, search "Regenerare urbană"
  // https://api.scicommtoolbox.ro/api/event-activities/noaptea-cercetatorilor-2021?page=1&perPage=4&lookup=Regenerare%20urbană
  // NC 2021, 4 per page, category "Concursuri și jocuri"
  // https://api.scicommtoolbox.ro/api/event-activities/noaptea-cercetatorilor-2021?page=1&perPage=4&categories[]=Concursuri%20%C8%99i%20jocuri
  // NC 2021, 4 per page, categories "Concursuri și jocuri" and "Webinarii"
  // https://api.scicommtoolbox.ro/api/event-activities/noaptea-cercetatorilor-2021?page=1&perPage=4&categories[]=Concursuri%20%C8%99i%20jocuri&categories[]=Webinarii
  // 

  React.useEffect(() => {
    fetch(componentProps.src)
    .then(response => response.json())
    .then(data => {
      // if (data.error) {
      //   this.setState({ error: data.error })
      // } else {
      setActivities(data.data.activities)
      setLoading(false)
      // }
    })
    .catch(error => {
      console.error(error);
      setLoading(true)
    })
  }, [])

  // console.log(componentProps)
  
  return (
    <StyledEngineProvider injectFirst>
      {title && <Typography variant="h4" component="h2">{ title }</Typography>}
      {/* <p>{ componentProps.src }</p> */}
      {componentProps.alert && <Box mb={2}>
        <Alert severity={componentProps.alert.alertType}>
          <AlertTitle>{componentProps.alert.alertTitle}</AlertTitle>
          {componentProps.alert.alertText}
        </Alert>
      </Box>}
      {description && <p>{description}</p>}
      {
        loading
          ? (
            <Box display="flex" flexDirection="column" alignItems="center">
              <Box textAlign="center" mb={2}>Se încarcă...</Box>
              <CircularProgress color="primary" />
            </Box>
          ) : (
            <Masonry
              breakpointCols={masonryBreakpointColumns}
              className={classes.masonryGrid}
              columnClassName={classes.masonryGridColumn}
            >
              {
                activities.data &&
                activities.data.map(activity => <ActivityCard activity={activity} route={componentProps.route} key={activity.uuid} />)
              }
            </Masonry>
          )
      }
    </StyledEngineProvider>
  )
}

export default SciComm