import React from 'react'
import {
  StyledEngineProvider,
  // LinearProgress,
  CircularProgress,
  // ThemeProvider,
  alpha,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(theme => ({
  root: {
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  circularProgress: {
    width: `${theme.spacing(24)} !important`,
    height: `${theme.spacing(24)} !important`,
    // color: theme.palette.background.ces,
  },
  circularProgressCircle: {
    strokeWidth: 0.75,
    fill: alpha(theme.palette.primary.main, 0.65),
  },
  cesLogoLoading: {
    position: 'absolute',
    marginTop: -4,
    marginLeft: -2,
  },
}))

export default function LinearDeterminate({ variant }) {
  const classes = useStyles()
  // eslint-disable-next-line
  const [progress, setProgress] = React.useState(0)

  React.useEffect(() => {
    const timer = setInterval(() => {
      setProgress(oldProgress => {
        if (oldProgress === 100) {
          return 0
        }
        const diff = Math.random() * 10
        return Math.min(oldProgress + diff, 100)
      })
    }, 15)

    return () => {
      clearInterval(timer)
    }
  }, [])

  return (
    <StyledEngineProvider injectFirst>
      <div className={classes.root}>
        {/* <LinearProgress variant={variant} value={progress} /> */}
        <CircularProgress
          classes={{
            root: classes.circularProgress,
            circle: classes.circularProgressCircle,
          }}
        />
        <img
          src="//educatiepentrustiinta.ro/images/cest-logo-white.128.png"
          alt="Loading..."
          className={classes.cesLogoLoading}
        />
      </div>
    </StyledEngineProvider>
  )
}
