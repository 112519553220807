import React from 'react'
// import PropTypes from 'prop-types'

// import ReactMarkdown from 'react-markdown'
import { withGoogleSheets } from 'react-db-google-sheets'

import { StyledEngineProvider, Grid } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles';

import RouteScrollTop from '../comps/RouteScrollTop'
import components from '../comps/components'

const useStyles = makeStyles(theme => ({
  organizationsContainer: {
    justifyContent: 'center',
    marginTop: theme.spacing(2),
  },
}))

function Organizations(props) {
  const classes = useStyles()
  const { dataSource, db, spacing } = props
  const team = db[dataSource]

  return (
    // <Container maxWidth="lg">
    <StyledEngineProvider injectFirst>
      <RouteScrollTop />
      <Grid
        container
        spacing={spacing}
        className={classes.organizationsContainer}
      >
        {dataSource &&
          team.map((data, index) => {
            // console.log(data)
            const RenderComponent = components[data.type]
            if (JSON.parse(data.display)) {
              return (
                <Grid item {...JSON.parse(data.props).grid} key={index}>
                  <RenderComponent dataSource={data} imageFolder={dataSource} />
                </Grid>
              )
            }
            return null
          })}
      </Grid>
    </StyledEngineProvider>
    // </Container>
  )
}

Organizations.propTypes = {}

export default withGoogleSheets()(Organizations)
