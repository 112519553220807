import React from 'react'
import PropTypes from 'prop-types'
import ReactMarkdown from 'react-markdown'
// import { withGoogleSheets } from './hooks/custom-react-db-google-sheets'
// import moment from 'moment'
// import 'moment/locale/ro'

import {
  StyledEngineProvider,
  // Container,
  Box, Paper, Typography
} from '@material-ui/core'
import { makeStyles } from '@material-ui/styles';

// import ImageIcon from '@material-ui/icons/Image'
// import ListIcon from '@material-ui/icons/ListAlt'
// import CalendarIcon from '@material-ui/icons/Event'
// import EmailIcon from '@material-ui/icons/Drafts'
// import LinkIcon from '@material-ui/icons/Link'
// import ShareIcon from '@material-ui/icons/Share'

import components from '../comps/components'

// import "./styles.css";

const useStyles = makeStyles(theme => ({
  heroContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: theme.spacing(theme.shape.verticalSpacer),

    [theme.breakpoints.up('sm')]: {
      marginTop: theme.spacing(-4),
    },
    [theme.breakpoints.only('xs')]: {
      marginTop: theme.spacing(-4.5),
    },
  },
  heroImage: {
    maxWidth: '100%',
    margin: '0 auto',
    // borderRadius: theme.shape.borderRadius
  },
  titleContainer: {
    marginBottom: theme.spacing(theme.shape.verticalSpacer),
  },

  title: {
    fontSize: 14,
  },

  sectionTitle: {
    margin: theme.spacing(6, 0, 3),
  },
}))

function HomePage({ config, dataSource }) {
  const classes = useStyles()

  // const openYCBM = url => {
  //   // console.log(url);
  //   window.open(url, '_blank')
  // }

  // const dataSource = props.db.homepage
  // const dataSource = props.db.Sheet1.sort((a, b) => {
  //   // console.log(parseInt(a.ID, a.FullyBooked, 10), parseInt(b.FullyBooked, 10));
  //   return parseInt(a.FullyBooked, 10) - parseInt(b.FullyBooked, 10)
  // })

  return (
    <StyledEngineProvider injectFirst>
      <Paper className={classes.heroContainer}>
        <img
          src={config.hero}
          alt={[config.siteTitle, config.siteDescription].join(', ')}
          className={classes.heroImage}
        />
      </Paper>
      <Box className={classes.titleContainer}>
        <Typography variant="h3" component="h1" gutterBottom align="center">
          {config.siteTitle}
        </Typography>
        <Typography variant="h5" component="h2" align="center">
          {config.siteDescription}
        </Typography>
      </Box>
      {dataSource &&
        dataSource.map((data, index) => {
          const RenderComponent = components[data.type]
          const getProps = {
            ...JSON.parse(data.props),
          }
          if (JSON.parse(data.display)) {
            return (
              <React.Fragment key={index}>
                {data.title &&
                  data.type !== 'Button' &&
                  data.type !== 'NavLink' &&
                  data.type !== 'Image' &&
                  data.type !== 'PageBanner' && (
                    <Typography
                      variant="h4"
                      {...getProps}
                      className={classes.sectionTitle}
                    >
                      {data.title}
                    </Typography>
                  )}
                {data.content &&
                  (data.type === 'Button' || data.type === 'NavLink' ? (
                    <React.Fragment>
                      {data.type === 'Button' && <RenderComponent
                        componentProps={getProps}
                        href={data.content}
                        title={data.title}
                        className={classes[`GSH${data.type}`]}
                      >
                        {data.title}
                      </RenderComponent>}
                      {data.type === 'NavLink' && <RenderComponent
                        componentProps={getProps}
                        to={data.content}
                        title={data.title}
                        className={classes[`GSH${data.type}`]}
                      >
                        {data.title}
                      </RenderComponent>}
                    </React.Fragment>
                  ) : (
                    <RenderComponent
                      {...getProps}
                      component={data.component || 'div'}
                      title={data.title && data.title}
                      className={classes[`GSH${data.type}`]}
                    >
                      {data.type === 'Typography' || data.type === 'Box' ? (
                        <ReactMarkdown children={data.content} />
                      ) : (
                        data.content
                      )}
                    </RenderComponent>
                  ))}
              </React.Fragment>
            )
          }
          return null
        })}
    </StyledEngineProvider>
  )
}

HomePage.propTypes = {
  db: PropTypes.shape({
    HomePage: PropTypes.arrayOf(PropTypes.object),
  }),
}

export default HomePage
